<script>
import { mapState } from 'vuex';
import html2canvas from 'html2canvas';
import platform from 'platform';
import postToFreshdesk from '@/api/postToFreshdesk';
import postScreenshotToFreshdesk from '@/api/postScreenshotToFreshdesk';
import Modal from './Modal.vue';

export default {
  components: { Modal },
  name: 'ReportBug',
  data() {
    return {
      showDialog: false,
      problemDescription: '',
      emailAddress: '',
      emailProvided: false,
      sending: false,
    };
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    // Create an HTML paragraph with an optional title.
    createParagraph(title, content) {
      const formattedTitle = title ? `<b>${title}:</b> ` : '';
      return `<p>${formattedTitle}${content}</p><br>`;
    },
    createTicketSubject() {
      let ticketSubject = 'User bug report';
      switch (process.env.VUE_APP_REFERRAL_SITE_TENANCY) {
        case 'sortlegal':
          ticketSubject += ' (Sort Legal onboarding)';
          break;
        case 'sortmove':
          ticketSubject += ' (Fast Start)';
          break;
        default:
          console.error('Unknown referral site');
      }
      return ticketSubject;
    },
    // Create the Freshdesk ticket 'description'.
    // This is the HTML content of the ticket.
    // See https://developer.freshdesk.com/api/#create_ticket
    createTicketDescription() {
      let description = '';
      description += this.createParagraph('Email', this.emailAddress);
      description += this.createParagraph(
        'Browser',
        `${platform.description} (${platform.name}) - version ${platform.version}`,
      );
      description += this.createParagraph('OS', JSON.stringify(platform.os));
      description += this.createParagraph('Description', this.problemDescription);
      description += this.createParagraph('URL', window.location.href);
      return description;
    },
    async sendBug() {
      this.sending = true;
      const ticketBody = {
        email: 'support@sortrefer.co.uk', // don't use client email as Freshdesk sends an email
        subject: this.createTicketSubject(),
        description: this.createTicketDescription(),
        type: 'Dev - Support Request',
        status: 2,
        priority: 1,
      };

      try {
        const result = await postToFreshdesk(ticketBody);
        const ticketId = result.data.ticketId;
        this.sending = false;
        this.toggleDialog();

        // Wait for the modal to disappear.
        // Specifically, $nextTick() waits for any DOM updates triggered by
        // reactive properties.
        await this.$nextTick();

        // Take a screenshot and attach to the ticket.
        const screenshotTarget = document.body;
        const base64Image = await html2canvas(screenshotTarget).then((canvas) => canvas.toDataURL('image/png'));
        await postScreenshotToFreshdesk({
          ticketId,
          image: base64Image,
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    if (this.displayName) {
      this.emailAddress = this.displayName;
      this.emailProvided = true;
    }
  },
  computed: mapState({
    displayName: (state) => state.auth.displayName,
  }),
};
</script>

<template>
  <span>
    <span @click="toggleDialog">
      <slot></slot>
    </span>
    <modal v-if="showDialog" :show-buttons="true" @proceed="toggleDialog">
      <template slot="header">Report a technical issue</template>
      <p class="mt-0">
        If you have a query about the process or how to complete your customer onboarding, please use the “Require
        Assistance?” link to get in touch with our customer service team who will be able to guide you through your
        onboarding.
      </p>
      <p class="mt-0">
        For technical issues, please provide the following information to help our team diagnose your problem; Your
        Name/Case Reference, the issue you are having, what step in the process you were trying to complete when the
        error occurred. Our team will then investigate the problem.
      </p>
      <div class="form-group" v-if="!emailProvided">
        <label for="emailAddress" class="mandatory">Email address</label>
        <input v-model="emailAddress" id="emailAddress" class="form-control" placeholder="youremail@provider.com" />
      </div>
      <div class="form-group">
        <label for="emailAddress" class="mandatory">Problem Description</label>
        <textarea
          v-model="problemDescription"
          id="problemDescription"
          class="form-control"
          placeholder="Please enter a description of the problem you have encountered."
        ></textarea>
      </div>
      <div class="text-right">
        <button class="btn btn-success" @click="sendBug" :disabled="!problemDescription || sending">
          Report Issue
          <i v-if="sending" class="spinner icon-spinner2 ml-2"></i>
        </button>
      </div>
    </modal>
  </span>
</template>
